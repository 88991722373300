import { Header, Footer, Banner } from '../../components'


function Layout({ children, pageTitle }) {
    return (
        <>
            <Header />
            <Banner pageTitle={pageTitle} />
            {children}


            <Footer />
        </>
    )
}

export default Layout