import React, { useState } from 'react'
import Logo from '../../assets/img/Logo.png'
import { Link } from 'react-router-dom'
import './style.css'
function Header() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    return (

        // <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        //     <div className="container">
        //         <a className="navbar-brand" href="#"><img src={Logo} height={50} /></a>
        //         <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
        //             <span className="navbar-toggler-icon"></span>
        //         </button>
        //         <div className="offcanvas offcanvas-end text-bg-dark" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
        //             <div className="offcanvas-header">
        //                 <h5 className="offcanvas-title fw-bold" id="offcanvasDarkNavbarLabel">Bootstrap 5 Navbar</h5>
        //                 <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        //             </div>
        //             <div className="offcanvas-body">
        //                 <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase active" aria-current="page" to="/">Home</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase" to="/contact-us">Registration</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase" to="/about">About</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase" to="/services">Our Services</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase" to="/services">Job Opening</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link text-uppercase" to="/contact-us">Contact Us</Link>
        //                     </li>

        //                 </ul>
        //                 <button className="btn btn-primary text-uppercase mt-3 mt-lg-0" type="button">Login</button>
        //             </div>
        //         </div>
        //     </div>
        // </nav>

        <nav className="navbar navbar-expand-lg  fixed-top" style={{ background: 'linear-gradient(to right, #3498db, #6a89cc)' }}>
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <div class="brand-logo" style={{ width: "100%" }}>
                        <a> <img src={Logo} height={50} alt="Logo" /></a>
                    </div>
                </Link>
                {/* <a className="navbar-brand" href="#"><img src={Logo} height={50} alt="Logo" /></a> */}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={!isNavCollapsed ? true : false}
                    onClick={handleNavCollapse}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" style={{ backgroundColor: '#ffffff' }}></span>
                </button> */}
                {/* <div className="offcanvas offcanvas-end text-bg-dark" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel"> */}
                <div
                    className={`${isNavCollapsed ? "collapse" : ""
                        } navbar-collapse`}
                    id="navbarNav"
                >

                    {/* <div className="offcanvas-header">
                        <h5 className="offcanvas-title fw-bold" style={{ color: '#ffffff' }} id="offcanvasDarkNavbarLabel">Your Brand</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div> */}
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase active" aria-current="page" to="/" style={{ color: '#ffffff' }}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/registration" style={{ color: '#ffffff' }}>Registration</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/about" style={{ color: '#ffffff' }}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/services" style={{ color: '#ffffff' }}>Our Services</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/job-openings" style={{ color: '#ffffff' }}>Job Openings</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/contact" style={{ color: '#ffffff' }}>Contact Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/jobs" style={{ color: '#ffffff' }}>Jobs</Link>
                            </li>
                            {sessionStorage.getItem('token') ? (
                                <li className="nav-item">
                                    <Link className="nav-link text-uppercase" to="/dashboard" style={{ color: '#ffffff' }}>Dashboard</Link>
                                </li>
                            ) : (
                                <li className='nav-item'>
                                    <Link to="/login">
                                        <button className='btn btn-outline-light text-uppercase mt-3 mt-lg-0' type="button">
                                            Login
                                        </button>
                                    </Link>
                                </li>
                            )}
                        </ul>

                        {/* <button className="btn btn-outline-light text-uppercase mt-3 mt-lg-0" type="button" >Login</button> */}

                    </div>
                </div>
            </div>
        </nav>







        // <header>
        //     <nav className="navbar navbar-expand-lg bg-body-tertiary bg-dark border-bottom border-body">
        //         <div className="container-fluid">
        //             <a className="navbar-brand" href="#"><img src={Logo} height={50} /></a>
        //             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        //                 <span className="navbar-toggler-icon"></span>
        //             </button>
        //             <div className="collapse navbar-collapse" id="navbarNav">
        //                 <ul className="navbar-nav">
        //                     <li className="nav-item">
        //                         <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link" to="/about">About Us</Link>
        //                     </li>
        //                     <li className="nav-item">
        //                         <Link className="nav-link" to="/services">Services</Link>
        //                     </li>

        //                     <li className="nav-item">
        //                         <Link className="nav-link" to="/contact-us">Contact Us</Link>
        //                     </li>
        //                 </ul>
        //             </div>

        //             <div className='navbar'>
        //                 <ul className="navbar-nav">
        //                     <li className="nav-item">

        //                         {isUserLoggedIn ?

        //                             <Link className='nav-link' to='/' onClick={(e) => { e.preventDefault(); setIsUserLoggedIn(false); sessionStorage.removeItem("auth") }}>Logout</Link>
        //                             :
        //                             <Link className='nav-link' to='/login'>Login | Signup</Link>
        //                         }

        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </nav>
        // </header>
    )
}
export default Header