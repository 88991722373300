import { apiGetUser } from "../../services/user";
import {
  SET_CURRENT_USER,

} from "./types";


import { jwtDecode } from "jwt-decode";
export const setCurrentUser = (data, cb) => async (dispatch) => {
  try {
    const res = await apiGetUser();

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
    cb();
  } catch (e) {
    console.log(e);
  }
};


export const setLogOut = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("token");
    dispatch({
      type: SET_CURRENT_USER,
      payload: null,
    });
  } catch (e) {
    console.log(e);
  }
};
