import publicReq, { privateReq, imageUpload } from "../config/AxiosConfig";

export const authLoginService = (data) => publicReq.post("/login", data);
export const registerService = data => privateReq.post('/register', data, {
    headers: {
        'Content-type': "multipart/form-data"
    },
    transformRequest: (data, res) => {
        return data;
    }
});
export const createRzpOrderService = (data) => privateReq.post('/create-order', data)
export const apiPayement = (data) => privateReq.post("/checkout-session", data);
export const apiGetUser = (data) => privateReq.get("/get-user", data);
export const apiGetALlTransactions = (data) => privateReq.get("/transactions");
export const updateTransactionService = (data) =>
    privateReq.patch("/transaction-status", data);
export const createUPIOrderService = (data) => privateReq.post('/create-order', data)
export const updateUPIOrderService = (data) => privateReq.post('/update-transaction', data)