import Layout from "../layout"
import ab1 from '../../assets/img/ab1.png'
import aobg from '../../assets/img/aobg.jpg'
import fb1 from '../../assets/img/fb1.jpeg'
import fb2 from '../../assets/img/fb2.jpeg'
import './style.css'
import { Link } from "react-router-dom"
function About() {
    return (
        <Layout pageTitle="About Us">
            <main>
                {/* <section class="py-3 py-md-5 py-xl-8">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-10 col-lg-8">
                                <h3 class="fs-5 mb-2 text-secondary text-uppercase">About Bhupathi Multitrade
                                </h3>
                                <h3 class="mb-4">We offer a comprehensive suite of recruitment services
                                </h3>
                                <h3 class="mb-4">We provide wide array of job listings from reputable companies
                                </h3>
                                <Link to="/registration"> <button type="button" class="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5">Connect Now</button></Link>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row gy-3 gy-md-4 gy-lg-0">
                            <div class="col-12 col-lg-6">
                                <div class="card bg-light p-3 m-0">
                                    <div class="row gy-3 gy-md-0 align-items-md-center">
                                        <div class="col-md-5">
                                            <img src={ab1} class="img-fluid rounded-start" alt="Why Choose Us?" />
                                        </div>
                                        <div class="col-md-7">
                                            <div class="card-body p-0">

                                                <p class="card-text lead">Welcome to Bhupathi Multitrade, your premier destination for employment opportunities! As a leading job-providing company, we are committed to connecting talented individuals with their dream careers and helping businesses thrive through exceptional talent acquisition. At Bhupathi Multitrade, we understand that finding the right job can be a transformative experience, and we aim to make that journey seamless and rewarding for both job seekers and employers. With our vast network and innovative approach, we are revolutionizing the way people find employment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="card bg-light p-3 m-0">
                                    <div class="row gy-3 gy-md-0 align-items-md-center">
                                        <div class="col-md-5">
                                            <img src={ab1} class="img-fluid rounded-start" alt="Visionary Team" />
                                        </div>
                                        <div class="col-md-7">
                                            <div class="card-body p-0">

                                                <p class="card-text lead">We take pride in our commitment to quality and professionalism. Our team of dedicated experts thoroughly screen and vet each job posting to ensure that only legitimate and desirable positions make their way onto our platform. We believe in transparency and integrity, and we strive to provide accurate and up-to-date information about each job, enabling you to make informed decisions about your career path.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <div class="container">
                    <div class="row gy-3 gy-md-4 gy-lg-0">
                        <div class="col-12 col-lg-6">
                            <div class="card bg-light p-3 m-0">
                                <div class="row gy-3 gy-md-0 align-items-md-center">
                                    <div class="col-md-5">
                                        <img src={fb1} class="img-fluid rounded-start" alt="Why Choose Us?" />
                                    </div>
                                    <div class="col-md-7">
                                        <h2 className="text-center"> MR R.S UPADHYAYA
                                            Founder & CEO
                                        </h2>
                                        <div class="card-body p-0">

                                            <p class="card-text lead">Welcome to the world of innovation and leadership led by Mr. R.S. Upadhyaya, the visionary Founder & CEO of our company. With a relentless commitment to excellence and a profound vision for the future, Mr. Upadhyaya has been instrumental in driving our organization towards success and growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card bg-light p-3 m-0">
                                <div class="row gy-3 gy-md-0 align-items-md-center">
                                    <div class="col-md-5">
                                        <img src={fb2} class="img-fluid rounded-start" alt="Visionary Team" />
                                    </div>
                                    <div class="col-md-7">
                                        <h2 className="text-center"> MR J.K UPADHYAYA
                                            DIRECTOR
                                        </h2>
                                        <div class="card-body p-0">

                                            <p class="card-text lead">   Meet Mr. J.K. Upadhyaya, our esteemed Director, a driving force behind our organization's success. With a wealth of experience and a strategic mindset, Mr. Upadhyaya plays a key role in shaping our company's vision and guiding us towards excellence.<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </main>
        </Layout >
    )
}
export default About