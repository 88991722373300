import Layout from "../layout"
import ab1 from '../../assets/img/ab1.png'
import aobg from '../../assets/img/aobg.jpg'
import acs from '../../assets/img/acs.png'
import hcs from '../../assets/img/hcs.png'
import ogs from '../../assets/img/ogs.jpg'
import tcs from '../../assets/img/tcs.jpg'
import t1 from '../../assets/img/t1.jpeg'
import t2 from '../../assets/img/t2.png'
import t3 from '../../assets/img/t3.jpg'

import './style.css'
import { Link } from "react-router-dom"
import { updateUPIOrderService } from "../../services/user"
import { useEffect } from "react"

function Home() {

    const updateTransaction = async () => {
        try {
            const params = new URLSearchParams(window.location.search);

            if (params.get('client_txn_id')) {
                const res = await updateUPIOrderService({
                    client_txn_id: params.get('client_txn_id')
                });
                if (res.data.status) {
                    sessionStorage.removeItem('token')
                    window.location.href = '/'
                }
            }
        } catch (e) {
            console.log(e)
            sessionStorage.removeItem('token')
            window.location.href = '/'
        }
    }

    useEffect(() => {
        if (window.location.search)
            updateTransaction()
    }, [])
    return (
        <Layout pageTitle="Home">
            <main>


                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={acs} class="d-block w-100 h-90 img1" alt="..." />
                            <div class="carousel-caption d-none d-md-block">
                                <h1>BHUPATHI MULTITRADE</h1>
                                <p>We offer a comprehensive suite of recruitment services
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src={hcs} class="d-block w-100 h-90 img1" alt="..." />
                            <div class="carousel-caption d-none d-md-block">
                                <h1>BHUPATHI MULTITRADE</h1>
                                <p>We provide wide array of job listings from reputable companies
                                </p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src={ogs} class="d-block w-100 h-90 img1" alt="..." />
                            <div class="carousel-caption d-none d-md-block">
                                <h1>BHUPATHI MULTITRADE</h1>
                                <p>We offer a comprehensive suite of recruitment services
                                </p>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <section class="py-3 py-md-5 py-xl-8">
                    <h2 className="text-center">About US</h2><br />


                    <section class="py-3 py-md-5 py-xl-8">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-md-10 col-lg-8">
                                    <h3 class="fs-5 mb-2 text-secondary text-uppercase">About Bhupathi Multitrade
                                    </h3>
                                    <h3 class="mb-4">We offer a comprehensive suite of recruitment services
                                    </h3>
                                    <h3 class="mb-4">We provide wide array of job listings from reputable companies
                                    </h3>
                                    <Link to="/registration"> <button type="button" class="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5">Connect Now</button></Link>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row gy-3 gy-md-4 gy-lg-0">
                                <div class="col-12 col-lg-6">
                                    <div class="card bg-light p-3 m-0">
                                        <div class="row gy-3 gy-md-0 align-items-md-center">
                                            <div class="col-md-5">
                                                <img src={ab1} class="img-fluid rounded-start" alt="Why Choose Us?" />
                                            </div>
                                            <div class="col-md-7">
                                                <div class="card-body p-0">

                                                    <p class="card-text lead">Welcome to Bhupathi Multitrade, your premier destination for employment opportunities! As a leading job-providing company, we are committed to connecting talented individuals with their dream careers and helping businesses thrive through exceptional talent acquisition. At Bhupathi Multitrade, we understand that finding the right job can be a transformative experience, and we aim to make that journey seamless and rewarding for both job seekers and employers. With our vast network and innovative approach, we are revolutionizing the way people find employment.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="card bg-light p-3 m-0">
                                        <div class="row gy-3 gy-md-0 align-items-md-center">
                                            <div class="col-md-5">
                                                <img src={ab1} class="img-fluid rounded-start" alt="Visionary Team" />
                                            </div>
                                            <div class="col-md-7">
                                                <div class="card-body p-0">

                                                    <p class="card-text lead">We take pride in our commitment to quality and professionalism. Our team of dedicated experts thoroughly screen and vet each job posting to ensure that only legitimate and desirable positions make their way onto our platform. We believe in transparency and integrity, and we strive to provide accurate and up-to-date information about each job, enabling you to make informed decisions about your career path.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </section>

                <section className="container my-5">
                    <h2 className="text-center mb-5">Why Choose Bhupathi Multitrade</h2>

                    <div className="row g-4">
                        <div className="col-md-4">
                            <div className="card h-100 p-4">
                                <i className="fas fa-search feature-icon mb-4"></i>
                                <h5 className="card-title">Efficient Screening</h5>
                                <p className="card-text">We lighten the load when it comes to screening, allowing you to focus on the most qualified candidates.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card h-100 p-4">
                                <i className="fas fa-stopwatch feature-icon mb-4"></i>
                                <h5 className="card-title">Quick Hiring Solutions</h5>
                                <p className="card-text">We offer quick hiring solutions with a vast talent pool available for work.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card h-100 p-4">
                                <i className="fas fa-handshake feature-icon mb-4"></i>
                                <h5 className="card-title">Valuable Service</h5>
                                <p className="card-text">We connect job seekers and employers, providing support and guidance throughout the hiring process.</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <h3 className="text-center mb-4">Our Expertise</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <i className="fas fa-industry expertise-icon mb-3"></i>
                                <p className="lead">Industry Expertise</p>
                                <p>Vashundhara International offers specialist recruiters for specific industries. We are a leading recruitment and executive search firm serving all industries.</p>
                            </div>
                            <div className="col-md-6">
                                <i className="fas fa-robot expertise-icon mb-3"></i>
                                <p className="lead">Artificial Intelligence</p>
                                <p>Partnering with Vashundhara International means finding the best talent and receiving support to maximize your business's potential. We provide talent and support to help businesses grow.</p>
                            </div>
                        </div>
                    </div>


                    <div className="mt-5">
                        <h3 className="text-center mb-4">Guide</h3>
                        <i className="fas fa-lightbulb guide-icon mb-3"></i>
                        <p className="lead text-center">In the corporate recruitment procedure, we excel in providing suitable candidates to every type of organization, including startups entering the market.</p>
                    </div>

                </section>

                {/* testmonial */}

                <section className="container my-5">
                    <h2 className="text-center mb-5">What Our Clients Say</h2>


                    <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">

                            <div className="carousel-item active">
                                <div className="testimonial">
                                    <a><img src={t1} height={100} width={100} /></a>
                                    <p className="lead">
                                        I had been searching for a job for several months without any luck, but then I came across Bhupathi Multitrade. Their team was incredibly supportive and guided me throughout the entire job search
                                        process. Thanks to their efforts, I secured a fantastic job that aligned perfectly with my skills and
                                        career goals. I highly recommend Bhupathi Multitrade to anyone looking for professional job
                                        placement services.
                                    </p>
                                    <blockquote className="blockquote">Sudheer Jadhav</blockquote>
                                </div>
                            </div>


                            <div className="carousel-item">
                                <div className="testimonial">
                                    <a><img src={t2} height={100} width={100} /></a>
                                    <p className="lead">"
                                        I cannot thank  Bhupathi Multitrade enough for their outstanding services. They not only helped me
                                        find a job that matched my qualifications but also provided valuable interview preparation and resume
                                        writing assistance. Their expertise and dedication are unmatched. Thanks to them, I landed a job at a
                                        reputable company within a short period. I am extremely grateful for their assistance and would recommend
                                        their services to anyone seeking employment.
                                        "</p>
                                    <blockquote className="blockquote">Jyoti Uttam</blockquote>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="testimonial">
                                    <a><img src={t3} height={100} width={100} /></a>
                                    <p className="lead">"
                                        Bhupathi Multitrade exceeded my expectations in every way. Their team of experts took the time to
                                        understand my career goals and aspirations, and they connected me with a job opportunity that perfectly
                                        aligned with my skills and interests. The personalized attention and support I received throughout the
                                        process were exceptional. I am grateful to  Bhupathi Multitrade for helping me kickstart my career
                                        on the right path.
                                        "</p>
                                    <blockquote className="blockquote">Harshit Pathak</blockquote>
                                </div>
                            </div>
                        </div>


                        <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </section>

            </main>
        </Layout>
    )
}

export default Home