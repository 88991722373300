
import React, { useEffect, useState } from 'react'
import { Home, About, Services, ContactUs, Login, Registration, AdminDashboard, PrivacyPolicy, JobOpening, TermsConditions, Page404 } from '../containers'
import Loader from '../containers/layout/Loader'
import { useDispatch, useSelector } from 'react-redux';
import {
    Routes,
    Route,
    BrowserRouter as Router,
    Navigate,
} from "react-router-dom";
// const router = createBrowserRouter([
//     {
//         path: '/',
//         element: <Home />
//     },
//     {
//         path: '/about',
//         element: <About />
//     },
//     {
//         path: '/services',
//         element: <Services />
//     },

//     {
//         path: '/contact-us',
//         element: <ContactUs />
//     },

//     {
//         path: '/login',
//         element: <Login />
//     },
//     {
//         path: '/register',
//         element: <Registration />
//     }
// ])
import { setCurrentUser } from "../redux/actions/auth";
import Success from '../containers/success';
import Failed from '../containers/failed';
function Root() {

    const [isLogin, setIsLogin] = React.useState(false);
    const [loader, setLoader] = React.useState(true);

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);


    React.useEffect(() => {
        getUser();
    }, []);
    const getUser = () => {
        let token = sessionStorage.getItem("token");
        if (token) {
            dispatch(
                setCurrentUser(token, () => {
                    setIsLogin(true);
                    setLoader(false);
                })
            );
        } else {
            setIsLogin(false);
            setLoader(false);
        }
    };

    const roleMap = () => {
        if (user) {
            switch (user.role) {
                case "admin":
                    return [
                        // {
                        //     path: "/",
                        //     element: filterLogin(<Page404 />),
                        // },
                        {
                            path: "/",
                            element: filterLogin(<Home />),
                        },

                        {
                            path: "/login",
                            element: <Login setIsLogin={setIsLogin} />,
                        },
                        {
                            path: "/dashboard",
                            element: <AdminDashboard />,
                        },

                        {
                            path: "/about",
                            element: <About />,
                        },
                        {
                            path: "/services",
                            element: <Services />,
                        },
                        {
                            path: "/contact",
                            element: <ContactUs />,
                        },
                        {
                            path: "/jobs",
                            element: <JobOpening />,
                        },
                        {
                            path: "/payment/success",
                            element: <Success />,
                        },
                        {
                            path: "/payment/failed",
                            element: <Failed />,
                        },
                        {
                            path: "/privacy",
                            element: <PrivacyPolicy />,
                        },
                        {
                            path: "/terms",
                            element: <TermsConditions />

                        }
                    ];
                case "user":
                    return [
                        // {
                        //     path: "/",
                        //     element: filterLogin(<Page404 />),
                        // },
                        {
                            path: "/",
                            element: filterLogin(<Home />),
                        },


                        {
                            path: "/about",
                            element: <About />,
                        },
                        {
                            path: "/services",
                            element: <Services />,
                        },
                        {
                            path: "/contact",
                            element: <ContactUs />,
                        },
                        {
                            path: "/jobs",
                            element: <JobOpening />,
                        },
                        {
                            path: "/dashboard",
                            element: <AdminDashboard />,
                        },
                        {
                            path: "/payment/success",
                            element: <Success />,
                        },
                        {
                            path: "/payment/failed",
                            element: <Failed />,
                        },
                        {
                            path: "/privacy",
                            element: <PrivacyPolicy />,
                        },
                        ,
                        {
                            path: "/terms",
                            element: <TermsConditions />

                        }
                    ];

            }
        }
        return [
            // {
            //     path: "/",
            //     element: filterLogin(<Page404 />),
            // },
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/registration",
                element: <Registration />,
            },
            {
                path: "/login",
                element: <Login setIsLogin={setIsLogin} />,
            },

            {
                path: "/about",
                element: <About />,
            },
            {
                path: "/contact",
                element: <ContactUs />,
            },

            {
                path: "/services",
                element: <Services />,
            },
            {
                path: "/jobs",
                element: <JobOpening />,
            },
            ,
            {
                path: "/payment/success",
                element: <Success />,
            },
            {
                path: "/payment/failed",
                element: <Failed />,
            },
            {
                path: "/privacy",
                element: <PrivacyPolicy />,
            },

            {
                path: "/terms",
                element: <TermsConditions />

            }
        ];
    };


    const filterLogin = (element) => {
        return element;
    };


    return loader ? (
        <Routes>
            <Route path="/*" element={<Loader />} />
        </Routes>
    ) : (
        <Routes>
            {roleMap().map((item, key) => {
                return <Route key={key} path={item.path} element={item.element} />;
            })}
            <Route name="/registration" element={<Registration />} />
            <Route name="/login" element={<Login />} />
        </Routes>
    );


}

export default Root