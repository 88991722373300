import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { apiGetALlTransactions } from '../../services/user';
import { printPageStyle } from "../../helpers";
import ReactToPrint from "react-to-print";
import moment from 'moment';
import AdmitCardFormat from '../../components/AdmitCardFormat';
const AdminDashboard = () => {
    const [payments, setPayments] = useState([]);
    const [feePaid, setFeePaid] = useState(false);
    const [printItems, setPrintItems] = useState([]);
    const [activeItem, setActiveItem] = useState([]);
    const [closeModal, setCloseModal] = useState(false);
    const [page, setPage] = useState(1);
    const admitCardFormatRef = useRef();

    useEffect(() => {
        onGetTransactions();
    }, [page]);

    const onGetTransactions = async () => {

        let res = await apiGetALlTransactions();
        if (res.data) {
            setPayments(res.data.users);

        }
    };
    const onSelect = (val, data) => {
        const items = [...printItems];
        let idx = items.findIndex((item) => item.challanNumber === +val);
        if (idx !== -1) {
            items.splice(idx, 1);
        } else {
            items.push(data);
        }

        setPrintItems(items);
    };
    const renderTransactions = (item) => {
        return (
            <tr

            >



                <td class="text-capitalize">{moment(item.user?.dob).format("DD-MM-YYYY")}</td>
                <td class="text-capitalize">{item?.user?.fullName}</td>
                <td class="text-capitalize">{item?.user?.fatherName}</td>
                <td class="text-capitalize">{item?.user?.email}</td>
                <td class="text-capitalize">{item?.user?.phone}</td>
                <td class="text-capitalize">{item?.user?.adhaarNumber}</td>
                <td class="text-capitalize">{item?.user?.address}</td>
                <td class="text-capitalize">{item?.user?.category}</td>
                {/* <td class="py-1 text-capitalize">{item.price}</td> */}
                {/* <td class="text-capitalize">{item.paymentStatus}</td> */}


                {/* <td>{moment(item?.user?.dob).format("DD-MM-YYYY")}</td> */}
                <td>
                    <a style={{
                        cursor: "pointer",
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }} download={item?.user?.qualificationDoc} target='_blank' href={item?.user?.qualificationDoc}>View</a>
                </td>

                <td
                // onClick={(e) => {
                //     e.preventDefault();
                //     setActiveItem(item);
                // }}
                >
                    <ReactToPrint
                        // onBeforePrint={() => setActiveItem(item)}
                        onBeforeGetContent={() =>
                            new Promise((resolve, reject) => {
                                setActiveItem(item);
                                let timeout = setTimeout(() => {
                                    resolve();
                                    clearTimeout(timeout);
                                }, 800);
                            })
                        }
                        pageStyle={printPageStyle}
                        trigger={() => (
                            <a
                                style={{
                                    cursor: "pointer",
                                    fontWeight: 'bold',
                                    textDecoration: 'underline'
                                }}
                            >
                                Print
                            </a>
                        )}
                        copyStyles={false}
                        content={() => admitCardFormatRef.current}
                    />
                </td>
            </tr>
        );
    };
    return (
        <>

            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 class="card-title">Dashboard</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Date of Birth</th>
                                        <th>Name</th>
                                        <th>Father Name</th>


                                        <th>Email</th>
                                        <th>Phone</th>

                                        <th>Adhaar Card</th>
                                        <th>Address</th>
                                        <th>Category</th>
                                        <th>Qualification</th>
                                        <th>Registration Slip</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {payments &&
                                        payments.length > 0 &&
                                        payments.map((i) => {
                                            return renderTransactions(i);
                                        })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <div className="d-none">

                <AdmitCardFormat
                    data={feePaid ? printItems : activeItem}
                    ref={admitCardFormatRef}
                />

            </div></>


    )
}

export default AdminDashboard;