import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import { Toaster } from 'react-hot-toast'
import Root from './routes/Root';
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <Provider store={store}>
      <Root />
      <Toaster />
    </Provider>
  );
}

export default App;