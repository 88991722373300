import Layout from "../layout"


function TermsConditions() {
    return (
        <Layout pageTitle="Terms Conditions">
            <main>

                <div class="container mt-5">


                    <h2 class="mb-4">Terms & Conditions</h2>

                    <p>Please read the following carefully. These terms and conditions ("Terms and Conditions") control your use of the Bhupathi Multitrade website. In these Terms and Conditions, "Bhupathi Multitrade" is referred to as the "Company," "us," or "we."</p>
                    <p>'You' refers to a user or a paying customer. If you are a company or another person who gives access to company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.</p>
                    <p>The Bhupathi Multitrade website (the 'Site'), the educational services made available through the site, and the content (the 'Products') are owned, operated, and maintained, as applicable, by Bhupathi Multitrade ('we', 'our', 'us', or the 'Company'). The Site, Products, and Content are, collectively, the 'Company Products'.</p>
                    <p>By (a) using or accessing the Company Products, including, but not limited to downloading or accessing, (b) offering a Course through the Site or through Software; you agree to the terms and conditions set forth in these Terms of Use (the "Terms").</p>
                    <p>By using this website or its Products and Services, You Agree and Warrant that you have read, understood, and agreed to be Bound by these terms. Company's privacy policy can be found at <a href="#">Privacy Policy</a>. If you do not accept these terms, you must not use - and are not authorized to use - all or any portion of the company's website and its products or services (as defined below).</p>

                    <p>Please read them carefully before you use the services of this site.</p>

                    <ol>
                        <li>
                            <p>You should not use this site in an unlawful manner; you must respect the website terms and conditions and follow the Privacy Policy.</p>
                        </li>

                        <li>
                            <p>Under no situations or circumstances, will the company be liable for any change in the content which it provides on the website through its products and services, including but not limited to any errors, omissions, loss, or damage experienced in connection with the use of exposure, any content made available via our products, services, or various resources such as email, blog, etc.</p>
                        </li>

                        <li>
                            <p>Our services are free to any user with access to the Internet. However, we are not responsible for the charges incurred for the usage of hardware, software, or internet services provider fee. Also, the user is fully responsible for the proper functioning of computer hardware and internet access.</p>
                        </li>

                        <li>
                            <p>You will be required to use login credentials for some of the sections on the site, and the company reserves the right to block access to our services for any user who does not follow these conditions.</p>
                        </li>

                        <li>
                            <p>We make sure that users get uninterrupted access to our service, but there is no obligation to do so.</p>
                        </li>

                        <li>
                            <p>Bhupathi Multitrade is not responsible and is not obligated for issues in your network or server beyond certain limits.</p>
                        </li>
                    </ol>


                    <br />




                    <h4 class="mt-4">Website Usage Guidelines</h4>
                    <ul>
                        <ol>
                            <li>Do not insult, abuse, harass, stalk, threaten, or otherwise infringe the rights of others;</li><br />
                            <li>Do not publish, post, distribute or disseminate any defamatory, infringing, indecent, offensive or unlawful material or information.</li><br />
                            <li>Do not upload, install, transfer files which are protected by Intellectual Property Laws or software which affect other computers.</li><br />
                            <li>It's prohibited to edit HTML source code, reverse engineer or attempt to hack.</li><br />
                            <li>Do not run Spam services/scripts or anything which could affect the infrastructure, and in turn, the users.</li><br />
                            <li>Do not communicate spam, advertise, or sell services such as digital downloads, eBooks, or phishing links.</li><br />
                            <li>You may not copy, distribute, and indulge in plagiarism with website content or user-submitted content.</li><br />
                            <li>Do not use any content which violates any legal, regulatory, Governmental or network operator conditions or codes of practice.</li><br />
                        </ol>
                    </ul>

                    <ol>

                        <h4 class="mt-4">Links and Hyperlinks Terms</h4>
                        <p>This website may have links to other websites. We do not undertake any control on the content of these websites; nor are we responsible for their website content. The sole purpose of the links included is to provide users information. Hence, Bhupathi Multitrade will not be held responsible.</p>

                        <ol>
                            <li>You may not mirror or frame the homepage or any other pages of this Site on any other website or webpage.</li><br />
                            <li>Do not link to Bhupathi Multitrade pages and subpages with spam links/anchor text, which could provide a false impression.</li><br />
                            <li>This may create misunderstanding for the users.</li><br />
                            <li>Do not use or include copyrighted or registered trademarks, or Intellectual property images, design or content as a link to Bhupathi Multitrade website.</li><br />
                            <li>Do not link to pages which support racism, terrorism.</li><br />
                            <li>Do not link to pages which provide pornographic content and violate human and animal rights.</li><br />
                            <li>Do not link pages to content which infringes the intellectual property of any third party, person or entity.</li><br />
                            <li>Do not link pages to content which violates any legal, regulatory, Governmental or network operator conditions or codes of practice.</li><br />
                        </ol>

                    </ol>
                    <ol>


                        <h4 class="mt-4">Copyright and Intellectual Property</h4>
                        <p>We value and respect others' intellectual property and expect our users to do the same.</p>

                        <p>The entire contents of the Site are protected by copyright and trademark laws. The owner of the copyrights and trademarks is Bhupathi Multitrade, its affiliates, or other third-party licensors. The material on the site, including text, graphics, code, and/or software, is copyrighted and belongs to Bhupathi Multitrade; therefore, you may not duplicate, modify, publish, or reproduce the content in any manner.</p>

                        <p>Bhupathi Multitrade does not take any responsibility for the content on other sites (except our partners and affiliates) that you may find when searching or accessing Bhupathi Multitrade products or services. The Privacy Policy and Terms of Use of the sites that you visit will administer that material.</p>

                        <p>Bhupathi Multitrade has all the rights to disable or prohibit access to users who do not respect and involve in the infringement of Bhupathi Multitrade intellectual property.</p>

                        <p>You are not allowed to use any of the digital images or logos from the website. In case of copyright issues, there has to be a written consent from the trademark owner.</p>
                        <p>Claims of Intellectual Property Violations
                        </p>
                        <p>If you believe that your work has been used without your permission in a way which prompts for copyright infringement. Please provide us with the information given below and we will act on it.</p>



                        <ol>
                            <li>The authorized person who will act on behalf of the owner of the copyright should send a digital or physical signature.</li><br />
                            <li>A description of the copyrighted work that you claim to be infringing your IP.</li><br />
                            <li>A description of where and how the material that you claim is infringing is located on the Bhupathi Multitrade website, with enough detail that we may find it on the website.</li><br />
                            <li>Contact Details - Address, telephone number, and email address.</li><br />
                            <li>A statement by you that the information which you provided is accurate and your claim of the copyright or intellectual property is on your owner's behalf.</li><br />
                            <li>You can reach Bhupathi Multitrade to notify your claims of copyright by writing to Help and Support Team.</li><br />
                        </ol>
                    </ol>

                    <ol>


                        <h4 class="mt-4">Transaction Terms</h4>
                        <p>If you believe that your work has been used without your permission in a way which prompts for copyright infringement, please provide us with the following information:</p>

                        <ol>
                            <li>To make a transaction on Bhupathi Multitrade website, you are bound to pay for that transaction.</li><br />
                            <li>Please pay close attention to your payment details such as the total bill, taxes, shipping costs, and discounts.</li><br />
                            <li>There are certain products which require additional Terms and Conditions which you have to agree to before you make the purchase.</li><br />
                            <li>If a delegate has completed the course and is eligible for a certificate, then the same shall be delivered to the address provided by the delegate within 30 working days.</li><br />
                            <li>We make no warranties of any kind, expressed or implied, with respect to any products or services sold on or through Bhupathi Multitrade.</li><br />
                            <li>No additional or different terms contained in any purchase order, document, transmission, or other communication shall be binding upon Bhupathi Multitrade unless agreed to by Bhupathi Multitrade in writing.</li><br />
                        </ol>

                    </ol>
                    <ol>

                        <h4 class="mt-4">Cancellation & Refund Policy</h4>
                        <p>We may cancel a contract if the service is not available for any reason. We will notify you if this is the case and return any payment that you have made within 7 days of purchase made on the website.</p>
                    </ol>
                </div>
            </main>
        </Layout>
    )
}

export default TermsConditions