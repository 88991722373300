import React, { useState } from "react"
import Layout from "../layout"
import './style.css'
import DatePicker from "react-datepicker";
import { apiPayement, createRzpOrderService, createUPIOrderService, registerService } from "../../services/user";
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import LoaderSvg from "../../assets/img/loader.svg";
import useRazorpay from "react-razorpay";
import { validateField } from "../../helpers";

function Registration() {
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const [fullName, setFullname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [profileImage, setProfiileImage] = useState("")
    const [qualificationDoc, setQualificationDoc] = useState("")
    const [adhaarNumber, setAdhaarNumber] = useState("")
    const [dob, setDob] = useState(new Date())
    const [residingState, setResidingState] = useState("")
    const [address, setAddress] = useState("")
    const [fatherName, setFatherName] = useState("")
    const [category, setCategory] = useState("")
    const [password, setPassword] = useState("")
    const [imageUrls, setImageUrls] = useState([]);
    const [loader, setLoader] = useState(false)

    const handlePfileImage = (e) => {
        const files = e.target.files[0];
        let arr = [];
        let urlArr = [];

        setImageUrls(URL.createObjectURL(files));
        setProfiileImage(files);
    };
    const handleQualificationDocs = (e) => {
        const files = e.target.files[0];
        let arr = [];
        let urlArr = [];

        setImageUrls(URL.createObjectURL(files));
        setQualificationDoc(files);
    };



    const onSubmit = async (e) => {
        e.preventDefault(e)
        let validate = true

        if (fullName === "") {
            validateField("fullName", "Name field is required")
            validate = false
        }
        if (email.trim() === "") {
            validateField("email", "Email field is required")
            validate = false
        }
        if (address.trim() === "") {
            validateField("address", "Adress field is required")
            validate = false
        }
        if (fatherName.trim() === "") {
            validateField("fatherName", "Father Name is required")
            validate = false
        }
        if (dob === "") {
            validateField("dob", "Date of birth is required")
            validate = false
        }
        if (phone.trim() === "") {
            validateField("phone", "Phone field is required")
            validate = false
        }

        if (phone.trim().length != 10) {
            validateField("phone", "Phone Number should be 10 numbers only")
            validate = false
        }



        if (password.trim() === "") {
            validateField("password", "Password field is required")
            validate = false
        }

        if (password.trim().length < 8) {
            validateField("password", "Password should be minimum 8 characters")
            validate = false
        }


        if (!validate) {
            toast.error("Please fill required fields")
            return
        }
        try {
            if (phone.length != 10) {
                toast.error("Invalid Phone Number")
                return
            }
            setLoader(true);
            const data = new FormData();
            data.append('fullName', fullName)
            data.append('phone', phone)
            data.append('email', email)
            data.append('profileImage', profileImage)
            data.append('qualificationDoc', qualificationDoc)
            data.append('adhaarNumber', adhaarNumber)
            data.append('dob', dob)
            data.append('residingState', residingState)
            data.append('address', address)
            data.append('fatherName', fatherName)
            data.append('category', category)
            data.append('password', password)

            const res = await registerService(data);

            if (res.data.status) {
                sessionStorage.setItem("token", res.data.token)
                setLoader(false);
                toast.success("Registered Successfully")

                // const pay = await apiPayement({
                //     price: category == "general" ? 300 : category == "obc" ? 250 : 200
                // });
                // if (pay.data.status) {
                //     window.location.href = pay.data.url;
                //     setLoader(false);
                // }
                await handlePayment()

                setFullname("")
                setPhone("")
                setEmail("")
                setProfiileImage("")
                setQualificationDoc("")
                setAdhaarNumber("")
                setDob("")
                setResidingState("")
                setAddress("")
                setFatherName("")
                setCategory("")
                setPassword("")
                // setLoader(false)
            } else {
                toast.error(res.data.message)
            }
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };

    // upi
    const handlePayment = async () => {
        try {

            const order = await createUPIOrderService({
                price: category == "general" ? 300 : category == "obc" ? 250 : 200,
                "customer_name": fullName,
                "customer_email": email,
                "customer_mobile": phone,
            })
            if (order.data.status) {
                window.location = order.data?.uri;
            }
            // const options = {
            //     key: process.env.REACT_APP_RZP_KEY_ID,
            //     amount: category == "general" ? "30000" : category == "obc" ? "25000" : "20000",
            //     currency: "INR",
            //     name: "Bhupathi Multitrade",
            //     description: "Registeration fee",
            //     // image: "https://example.com/your_logo",
            //     order_id: order?.data?.id,
            //     handler: async (res) => {
            //         console.log(res);
            //         const pay = await apiPayement({
            //             price: category == "general" ? 300 : category == "obc" ? 250 : 200,
            //             paymentId: res.razorpay_payment_id,
            //             sessionId: res.razorpay_order_id
            //         });
            //         if (pay.data.status) {
            //             // toast.success("Payment completed")
            //             window.location.href = '/payment/success';
            //             setLoader(false);
            //         }
            //     },
            //     prefill: {
            //         name: fullName,
            //         email: email,
            //         contact: phone,
            //     },
            //     // notes: {
            //     //     address: "Razorpay Corporate Office",
            //     // },
            //     theme: {
            //         color: "#5a8dd0",
            //     },
            // };

            // const rzpay = new Razorpay(options);
            // rzpay.on("payment.failed", function (response) {
            //     console.log(response)
            //     // window.location.href = '/payment/failed';

            //     // toast.error("Payment failed!")
            // });
            // rzpay.open();
        } catch (err) {
            console.log(err)
        }
    };
    // razorpay
    // const handlePayment = async () => {
    //     try {

    //         const order = await createRzpOrderService({
    //             price: category == "general" ? 30000 : category == "obc" ? 25000 : 20000,
    //         })
    //         const options = {
    //             key: process.env.REACT_APP_RZP_KEY_ID,
    //             amount: category == "general" ? "30000" : category == "obc" ? "25000" : "20000",
    //             currency: "INR",
    //             name: "Bhupathi Multitrade",
    //             description: "Registeration fee",
    //             // image: "https://example.com/your_logo",
    //             order_id: order?.data?.id,
    //             handler: async (res) => {
    //                 console.log(res);
    //                 const pay = await apiPayement({
    //                     price: category == "general" ? 300 : category == "obc" ? 250 : 200,
    //                     paymentId: res.razorpay_payment_id,
    //                     sessionId: res.razorpay_order_id
    //                 });
    //                 if (pay.data.status) {
    //                     // toast.success("Payment completed")
    //                     window.location.href = '/payment/success';
    //                     setLoader(false);
    //                 }
    //             },
    //             prefill: {
    //                 name: fullName,
    //                 email: email,
    //                 contact: phone,
    //             },
    //             // notes: {
    //             //     address: "Razorpay Corporate Office",
    //             // },
    //             theme: {
    //                 color: "#5a8dd0",
    //             },
    //         };

    //         const rzpay = new Razorpay(options);
    //         rzpay.on("payment.failed", function (response) {
    //             console.log(response)
    //             // window.location.href = '/payment/failed';

    //             // toast.error("Payment failed!")
    //         });
    //         rzpay.open();
    //     } catch (err) {
    //         console.log(err)
    //     }
    // };
    return (
        <Layout pageTitle="Registration">
            <main>

                <div className="container">
                    <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', margin: '20px' }}>
                        <h2 className="mb-4">Student Registration Form</h2>
                        <p><a style={{ fontWeight: 'bold' }} download target='_blank' href="https://bupathi-images.s3.ap-south-1.amazonaws.com/vaccancy-detail.pdf">Notification</a></p>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="fullName">Full Name*</label>
                                <input type="text" className="form-control" id="fullName" placeholder="Enter your full name" name="fullName" value={fullName} onChange={(e) => {
                                    setFullname(e.target.value);
                                }} />
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="fathersName">Father's Name*</label>
                                <input type="text" className="form-control" id="fathersName" placeholder="Enter your father's name" name="fatherName" value={fatherName} onChange={(e) => {
                                    setFatherName(e.target.value);
                                }} />
                            </div>
                        </div>


                        <div className="form-group">
                            <label htmlFor="email">Email Address*</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter your email address" name="email" value={email} onChange={(e) => {
                                setEmail(e.target.value);
                            }} />
                        </div>


                        <div className="form-group">
                            <label htmlFor="contactNumber">Contact No.*</label>
                            <input type="tel" className="form-control" id="contactNumber" placeholder="Enter your contact number" name="phone" value={phone} onChange={(e) => {
                                setPhone(e.target.value);
                            }} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password*</label>
                            <input type="password" className="form-control" id="password" placeholder="Enter your password" name="password" value={password} onChange={(e) => {
                                setPassword(e.target.value);
                            }} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="aadhaarNumber">Aadhaar Number*</label>
                            <input type="text" className="form-control" id="aadhaarNumber" placeholder="Enter your Aadhaar number" value={adhaarNumber} name="adhaarNumber" onChange={(e) => {
                                setAdhaarNumber(e.target.value);
                            }} />
                        </div>

                        <div className="form-row row" >

                            <div className="form-group col-md-6" style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor="dob">Date of Birth*</label>
                                <DatePicker
                                    className="form-control"
                                    selected={dob}
                                    dateFormat={"dd-MM-yyyy"}
                                    // onSelect={handleDateSelect}
                                    placeholderText="Date of birth"
                                    name="dob"
                                    value={dob}
                                    onChange={(e) => {
                                        setDob(e);
                                    }} //only when value has changed
                                />
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="category">Select Category*</label>
                                <select className="form-control" id="category"
                                    value={category}
                                    name="category"
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                    }}>
                                    <option value="" disabled selected>Select Category</option>
                                    <option value="general">General</option>
                                    <option value="obc">OBC</option>
                                    <option value="sc_st">SC/ST</option>
                                </select>
                            </div>
                        </div>


                        <div className="form-group">
                            <label htmlFor="state">Select State*</label>
                            <select value={residingState} className="form-control"
                                name="residingState" id="state" required onChange={(e) => {
                                    setResidingState(e.target.value);
                                }}>
                                <option value="" disabled selected>Select State</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Puducherry">Puducherry</option>
                            </select>
                        </div>


                        <div className="form-group">
                            <label htmlFor="address">Complete Address</label>
                            <textarea className="form-control" id="address" placeholder="Enter your complete address" value={address} name="address" onChange={(e) => {
                                setAddress(e.target.value);
                            }}></textarea>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="photo">Upload Your Photo</label>
                                <input type="file" className="form-control-file" id="photo"
                                    onChange={handlePfileImage}

                                    multiple

                                    accept="image/*" />
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="qualificationDoc">Upload Highest Qualification Document</label>
                                <input type="file"
                                    accept="file"
                                    multiple
                                    onChange={handleQualificationDocs}
                                    className="form-control-file" id="qualificationDoc" />
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>


                    </form><br />
                </div>

            </main>
        </Layout>
    )
}
export default Registration