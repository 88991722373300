import './style.css'
import Logo from '../../assets/img/Logo.png'
import { TermsConditions } from '../../containers';
import { Link } from 'react-router-dom';
function Footer() {
    return (

        <section id="footer">
            <div className="container">
                <div className="row text-center text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <a className="navbar-brand" href="#"><img src={Logo} height={50} alt="Logo" /></a><br /><br />
                        <ul className="list-unstyled quick-links">
                            <li><p className='text-white'>At Bhupathi Multitrade, we maintain a dynamic and intelligible database of experienced professionals based on their qualifications, experience, and track records.</p></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Quick links</h5>
                        <ul className="list-unstyled quick-links">
                            <li><Link to="/registration"><i className="fa fa-angle-double-right"></i>Registration</Link></li>
                            {/* <li><a href=""><i className="fa fa-angle-double-right"></i>About Us</a></li>
                            <li><a href=""><i className="fa fa-angle-double-right"></i>Our Services</a></li>
                            <li><a href=""><i className="fa fa-angle-double-right"></i>Jobs</a></li> */}
                            {/* <li><a href=""><i className="fa fa-angle-double-right"></i>Support</a></li> */}
                            <li><Link to="/terms"><i className="fa fa-angle-double-right"></i>Terms & Conditions</Link></li>
                            <li><Link to="/privacy"><i className="fa fa-angle-double-right"></i>Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Contact Us</h5>
                        <ul className="list-unstyled quick-links">
                            <li><p className='text-white'>Office No 7, 6th Floor, Saran Chamber - 1, Park Road -5, Hazratganj, Lucknow - 226001</p></li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">

                        <p className="h6">Copyright © 2023 Bhupathi Multitrade | All Rights Reserved | Privacy Policy<Link className="text-green ml-2" to="/privacy" target="_blank"> </Link></p>
                    </div>

                </div>
            </div>
        </section >

    )
}

export default Footer;