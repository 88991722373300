import React, { useEffect } from "react";
const Failed = () => {
    useEffect(() => {
        sessionStorage.removeItem('token')
    }, [])
    return (

        <div>Your payment process not completed. Please try again</div>
    )
}

export default Failed;