import Layout from "../layout"
import acs from '../../assets/img/acs.png'
import hcs from '../../assets/img/hcs.png'
import ogs from '../../assets/img/ogs.jpg'
import tcs from '../../assets/img/tcs.jpg'
import s1 from '../../assets/img/s1.png'
import s2 from '../../assets/img/s2.png'
import s3 from '../../assets/img/s3.png'
import s4 from '../../assets/img/s4.png'
import aobg from '../../assets/img/aobg.jpg'
function Services() {
    return (
        <Layout pageTitle="Our Services">
            <main>

                <section className="py-5 py-xl-8">
                    <div className="container overflow-hidden">
                        <div className="row gy-5">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">
                                    <a><img src={s1} height={300} width={400} /></a>
                                    <h5 className="m-2">Agriculture Services</h5>
                                    <p className="m-0 text-secondary">The Indian Agricultural Industry has ample job opportunities. Bhupathi Multitrade is an experienced Indian Agriculture Recruitment Agency devoted to meet the extensive requirement for premium quality employees.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">
                                    <a><img src={s2} height={300} width={400} /></a>
                                    <h5 className="m-2">Healthcare Services</h5>
                                    <p className="m-0 text-secondary">Bhupathi Multitrade is the leading Manpower Consultancy and has a track record of consistently providing highly skilled professionals, to some of the premier healthcare service providers and hospitals.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">
                                    <a ><img src={s3} height={300} width={400} /></a>
                                    <h5 className="m-2">Oil & Gas Services</h5>
                                    <p className="m-0 text-secondary">Having worked with Government, Semi-Government and private entities over the last four decades, Bhupathi Multitrade is the one-stop, end-to-end manpower solutions provider for talent across all verticals. </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">
                                    <a><img src={s4} height={300} width={400} /></a>
                                    <h5 className="m-2">Telecom Services</h5>
                                    <p className="m-0 text-secondary">The Indian Telecom industry is in a phase of consolidation after witnessing tremendous growth over the past few decades. However,the Telecom sector is likely to create few lakh new jobs on the back of technological advancements.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">

                                    <h5 className="m-2">We are in this Business since a long time during which our company has registered exponential growth.</h5>
                                    <p className="m-0 text-secondary">We pride ourselves with having carved a niche for ourselves by offering solutions based on a combination of deep industry knowledge, customer-centric approach, coupled with the latest information technology systems. We continue to assist our clients by selecting and deploying the most versatile and experienced workforces to suit a large variety of operations for unskilled, skilled, and specialized business requirements across the globe.</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="text-center px-xl-2">
                                    <a><img src={aobg} height={300} width={400} /></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default Services