export const validateField = (field, message = "This field is required") => {
  function insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }
  function removeElementsByClass(curField, className) {
    const elements = curField.parentNode.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }
  let curField = document.getElementsByName(field)[0];
  removeElementsByClass(curField, "invalid-feedback");
  window.scrollTo(500, 0);
  curField.classList.add("border-danger");
  curField.classList.add("border-danger");
  let error = document.createElement("div");
  error.classList.add("invalid-feedback", "text-start");
  error.textContent = message;
  error.style.display = "block";
  insertAfter(error, curField);
};

export const printPageStyle = `
  /* reset */
  
  *
  {
    border: 0;
    box-sizing: content-box;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: top;
  }
  
  /* content editable */
  
  *[contenteditable] { border-radius: 0.25em; min-width: 1em; outline: 0; }
  
  *[contenteditable] { cursor: pointer; }
  
  *[contenteditable]:hover, *[contenteditable]:focus, td:hover *[contenteditable], td:focus *[contenteditable], img.hover { background: #DEF; box-shadow: 0 0 1em 0.5em #DEF; }
  
  span[contenteditable] { display: inline-block; }
  
  /* heading */
  
  h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }
  
  /* table */
  
  table { font-size: 75%; table-layout: fixed; width: 100%; }
  table { border-collapse: separate; border-spacing: 2px; }
  th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
  th, td { border-radius: 0.25em; border-style: solid; }
  th { background: #EEE; border-color: #BBB; }
  td { border-color: #DDD; }
  
  /* page */
  
  html { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }
  html { background: #999; cursor: default; }
  
  body { box-sizing: border-box; height: 11in; margin: 0 auto;  padding: 0.5in; width: 8.5in; }
  body { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }
  
  /* header */
  .border { border: 0.5px solid #000;margin:2rem 0; padding:10px; }
  header { margin: 0 0 1.1em; }
  header:after { clear: both; content: ""; display: table; }
  
  header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }
  header address { float: left; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0; }
  
  header span, header img { display: block; float: right; }
  header span { margin: 0; max-height: 25%; max-width: 60%; position: relative; } 
  
  /* article */
  
  article, article address, table.meta, table.inventory { margin: 0 0 0.5em; }
  article:after { clear: both; content: ""; display: table; }
  article h1 { clip: rect(0 0 0 0); position: absolute; }
  
  article address { float: left; font-size: 125%; font-weight: bold; }
  
  /* table meta & balance */
  
  table.meta, table.balance { float: right; width: 36%; }
  table.meta:after, table.balance:after { clear: both; content: ""; display: table; }
  
  /* table meta */ 
  table.meta th { width: 40%; }
  table.meta td { width: 60%; }
  
  /* table items */
  
  table.inventory { clear: both; width: 100%; }
  table.inventory th { font-weight: bold; text-align: center; }
  
  table.inventory td:nth-child(1) { width: 26%; }
  table.inventory td:nth-child(2) { width: 38%; }
  table.inventory td:nth-child(3) { text-align: right; width: 12%; }
  table.inventory td:nth-child(4) { text-align: right; width: 12%; }
  table.inventory td:nth-child(5) { text-align: right; width: 12%; }
  
  /* table balance */
  
  table.balance th, table.balance td { width: 50%; }
  table.balance td { text-align: right; }
  
  /* aside */
  
  aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
  aside h1 { border-color: #999; border-bottom-style: solid; }
  @media print {
    * { -webkit-print-color-adjust: exact; }
    html { background: none; padding: 0; }
    body { box-shadow: none; margin: 0; }  
    .page-break { page-break-before: always } 
  }
  
  @page { margin: 0; }
  `;
