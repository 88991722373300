import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index.js';
const intialState = {}

const middleware = [thunk]

const middlewareApply = applyMiddleware(...middleware)

const store = createStore(
  rootReducer,
  intialState,
  compose(middlewareApply),

);

export default store;