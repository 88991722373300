import React from 'react';

import LoaderSvg from '../../assets/loader.svg'
import Logo from '../../assets/img/Logo.png'

const Loader = () => {

    return (
        <div class="container-fluid page-body-wrapper full-page-wrapper page-login">
            <div class="content-wrapper d-flex align-items-center auth px-0">
                <div class="row w-100 mx-0">
                    <div class="col-lg-4 mx-auto">
                        <div class="auth-form-light text-center py-5 px-4 px-sm-5">
                            <div class="brand-logo" style={{ width: '100%' }}>

                                <img src={Logo} />
                                <h3>Bupathi<span style={{ color: "#f00032" }}>Multitrade </span></h3>
                            </div>

                            <h4>Loading...<br /><img src={LoaderSvg} height="50" /></h4>

                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Loader