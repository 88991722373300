import axios from "axios";
const publicReq = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ?
      "http://localhost:5000/api"
      : "https://api.bhupathimultitrade.com/api"

});

const privateReq = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ?
      "http://localhost:5000/api"
      : "https://api.bhupathimultitrade.com/api"



});

const imageReq = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ?
      "http://localhost:5000/api"
      :
      "https://api.bhupathimultitrade.com/api"


});
privateReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
imageReq.defaults.headers.common["Content-Type"] = "multipart/form-data";
imageReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export { privateReq };
export default publicReq;
