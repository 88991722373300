import React from "react";
import moment from "moment";

const AdmitCardFormat = React.forwardRef((props, ref) => {
  const { data } = props;

  return (
    <div ref={ref}>

      <>

        <div className="border">

          <article>
            <div>
              {/* <h1 className="text-center" style={{ textAlign: 'center' }}>Admit Card</h1> */}

              <p className="text-center" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '35px' }}> Bhupathi Multitrade Pvt Ltd</p>
            </div>

            <div style={{ marginTop: '15px', marginBottom: '20px' }}>
              <p className="text-center" style={{ textAlign: 'center', lineHeight: '20px', fontWeight: 'bold', fontSize: '15px' }}> Address-:Saran Chamber-1, 5, Park Rd, Raj Bhavan Colony, Hazratganj, <br /> Lucknow,
                Uttar Pradesh 226001<br />  Customer Support Whatsapp/Call+91 90052 33518,<br /></p>

              <p className="text-center" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}> Support: Info.bhupathiofficial@gmail.com</p>
              <p className="text-center" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '35px', marginTop: '25px' }}> Registration Slip</p>
            </div>
            <table className="inventory">

              <thead>
                <tr style={{ flexDirection: 'row', display: 'flex' }}>
                  <div className="my-4">
                    <img src={data?.user?.profileImage} style={{ height: '100px', width: '100px' }} />
                  </div>

                </tr>
                <tr >
                  {/* <div style={{ aligndatas: 'center', justifyContent: 'space-between', display: 'flex', marginBottom: '10px' }}>
                            <div><span style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '20px' }}>{data.paymentStatus == true ? "Paid" : "Pending"}</span>
                            </div>
                           
                          </div> */}
                </tr>

                <tr>

                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Father Name</span>
                  </th>
                  <th>
                    <span>Date of Birth</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>

                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                      {data?.user?.fullName}
                    </p>
                  </td>

                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                      {data?.user?.fatherName}

                    </p>
                  </td>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}> {moment(data?.user?.dob).format("DD-MM-YYYY")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="inventory">
              <thead>
                <tr >
                  {/* <div style={{ aligndatas: 'center', justifyContent: 'space-between', display: 'flex', marginBottom: '10px' }}>
                            <div><span style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '20px' }}>{data.paymentStatus == true ? "Paid" : "Pending"}</span>
                            </div>
                           
                          </div> */}
                </tr>

                <tr>
                  <th>
                    <span>Phone</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Adhaar Card</span>
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.phone}</p>
                  </td>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.email}</p>
                  </td>

                  <td>

                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.adhaarNumber}</p>


                  </td>

                </tr>
              </tbody>

            </table>
            <table className="inventory">
              <thead>


                <tr>
                  <th>Category</th>
                  <th>
                    <span>Adress</span>
                  </th>
                  <th>
                    <span>State</span>
                  </th>


                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.category}</p>
                  </td>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.address}</p>
                  </td>
                  <td>
                    <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{data?.user?.residingState}</p>
                  </td>



                </tr>
              </tbody>
            </table>
            <div style={{ marginTop: '20px' }}>
              <p>
                Declaration / घोषणा</p><br />
              <p>  Declaration / घोषणा
                I declare that all the information given by me in this form is correct to the best of my knowledge
                and belief. I also assure that if any of the above statements are found to be false, then I am liable
                to be disqualified and my admission can be canceled.

              </p><br />
              <p style={{ lineHeight: '20px' }}> मैं इस बात की घोषणा करता/करती हूँ कि मेरे द्वारा इस फॉर्म में दी गई सभी जानकारी मेरे ज्ञान और विश्वास के अनुसार सही है। मैं यह भी आश्वस्त कराता हूँ कि यदि उपरोक्त कथनों में से कोई भी कथन गलत पाया जाता है तो मैं अयोग्य घोषित होने के लिए उत्तरदायी हूँ और मेरा प्रवेश रद्द किया जा सकता है।


              </p><br />

            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginBlock: '20px' }}> Important Instructions</p>
              <p>1. In case of any discrepancy found in the above details please inform us immediately at
                Info.bhupathiofficial@gmail.com</p><br />
              <p>2. Please visit www.bhupathimultitrade.com regarding Admissions & Certification verification.</p><br />


              <p style={{ fontWeight: 'bold' }}>महत्वपूर्ण निर्देश </p><br />
              <p>1.उपरोक्त विवरण में पाई गई किसी भी विसंगति के मामले मे Info.bhupathiofficial@gmail.com .com पर हमें तुरंत सूचित करें।</p><br />
              <p>
                2.कृपया नामांकन और प्रमाणन सत्यापन के संबंध में www.bhupathimultitrade.com  पर जाएं।</p><br />



            </div>
          </article>
        </div>

      </>

    </div>
  );
});
export default AdmitCardFormat;
