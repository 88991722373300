import React, { useState } from "react";
import Layout from "../layout"
import Logo from '../../assets/img/Logo.png'
import { authLoginService } from '../../services/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateField } from '../../helpers';
import toast from 'react-hot-toast';
import LoaderSvg from "../../assets/img/loader.svg";

import './style.css'
function Login() {
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState("");

    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);

    const onSubmit = async (e) => {
        e.preventDefault();
        let validate = true;

        if (phone.trim() === "") {
            toast.error("Phone field is required")
            // validateField("phone", "Phone field is required");
            validate = false;
        }

        if (phone.trim().length != 10) {
            toast.error("Phone Number should be 10 numbers only")

            // validateField("phone", "Phone Number should be 10 numbers only");
            validate = false;
        }

        if (password.trim() === "") {
            // validateField("password", "Password field is required");
            toast.error("Password field is required")

            validate = false;
        }

        // if (password.trim().length < 8) {
        //     toast.error("Password should be minimum 8 characters")

        //     // validateField("password", "Password should be minimum 8 characters");
        //     validate = false;
        // }

        if (!validate) {
            // toast.error("Please fill required fields");

            return;
        }
        try {
            setLoader(true);
            const res = await authLoginService({
                phone,
                password,
            });

            if (res.data.token) {

                sessionStorage.setItem("token", res.data.token);
                toast.success("Login Succesfully");

                setLoader(false);
                navigate("/", { replace: true })
                window.location.href = "/";

                //   })
                // );
            }
        } catch (err) {
            setLoader(false);
            if (err.response.data.message) {
                toast.error(err.response.data.message);
            }
        }
    };
    return (
        <Layout pageTitle="Login form">
            <main>

                <div className="login-page bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <h3 className="mb-3">Login Now</h3>
                                <div className="bg-white shadow rounded">
                                    <div className="row">
                                        <div className="col-md-7 pe-0">
                                            <div className="form-left h-100 py-5 px-5">
                                                <form action="" onSubmit={onSubmit} className="row g-4">
                                                    <div className="col-12">
                                                        <label>Phone<span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <div className="input-group-text"><i className="bi bi-person-fill"></i></div>
                                                            <input type="text" className="form-control" placeholder="Enter Phone number"
                                                                value={phone}
                                                                onChange={(e) => {
                                                                    setPhone(e.target.value);
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label>Password<span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <div className="input-group-text"><i className="bi bi-lock-fill"></i></div>
                                                            <input type="text" className="form-control" placeholder="Enter Password"
                                                                value={password} onChange={(e) => {
                                                                    setPassword(e.target.value);
                                                                }} />
                                                        </div>
                                                    </div>



                                                    <div className="col-sm-6"
                                                    >
                                                        <a href="#" className="float-end text-primary" style={{ textAlign: 'end' }}>Forgot Password?</a>
                                                    </div>

                                                    <button type="submit" class="btn btn-primary me-2">{loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"}</button>
                                                    {/* <div className="col-12">
                                                        <button type="submit" className="btn btn-primary px-4 float-end mt-4">login</button> */}
                                                    {/* </div> */}
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-5 ps-0 d-none d-md-block">
                                            <div className="form-right h-100 bg-primary text-white text-center pt-5"><br /><br />
                                                <a className="navbar-brand" href="#"><img src={Logo} height={50} alt="Logo" /></a><br /><br /><br />
                                                <h2 className="fs-1">Welcome Back!!!</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </main>
        </Layout>
    )
}
export default Login