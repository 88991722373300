import React, { useEffect } from "react";
import { updateTransactionService } from '../../services/user';
import toast from 'react-hot-toast';
const Success = () => {
    useEffect(() => {
        sessionStorage.removeItem('token')
        let timeout = setTimeout(() => {
            window.location.href = "/";
            clearTimeout(timeout);
        }, 1000);
        // let timeout = setTimeout(() => {
        //     showToastTransactionAlert();
        // }, 800);
        // return () => clearTimeout(timeout);
    }, []);

    const showToastTransactionAlert = async () => {
        const params = new URLSearchParams(window.location.search);

        await updateTransaction(params.get("id"));

    };

    const updateTransaction = async (id) => {
        try {
            const res = await updateTransactionService({
                id,

            });
            if (res.data.status) {
                toast.success(
                    `Payment done successfully!\nFee paid`
                );


            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (

        <div className="container" style={{ paddingTop: '100px' }}><p>Your exam fee received successfully. <br />You will get admit card by post within 7 Days </p></div>
    )
}

export default Success;