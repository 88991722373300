
import './style.scss'
export default function Banner({ pageTitle }) {


    return (

        <section className={pageTitle == 'Home' ? 'banner' : 'banner page'}>
            {pageTitle != 'Home' &&
                <div className='container'>
                    <h1>{pageTitle}</h1>
                </div>
            }
        </section>
    )
}