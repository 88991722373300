import Layout from "../layout"
import about1 from '../../assets/img/about1.jpg'
import { Link } from "react-router-dom"
import './style.css'
function JobOpening() {
    return (
        <Layout pageTitle="Our Vacancies">
            <main>
                <div class="container">

                    <div class="row">


                        <div class="col-md-4 job-card">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title">ब्लॉक स्तर 3100 पद शिक्षा इंटर पास वेतन  16500+ TA/DA</h5>
                                    <p className="card-text">Description of the job position. Highlight key responsibilities and qualifications.</p>
                                    <Link to="/registration" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-4 job-card">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title">जिला स्तर 1625 शिक्षा ग्रेजुएशन वेतन 23800+TA/DA</h5>
                                    <p className="card-text">Description of the job position. Highlight key responsibilities and qualifications.</p>
                                    <Link to="/registration" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-4 job-card">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title">प्रदेश स्तर 75 पद शिक्षा ग्रेजुएशन/ एग्रीकल्चर  31800+TA/DA</h5>
                                    <p className="card-text">Description of the job position. Highlight key responsibilities and qualifications.</p>
                                    <Link to="/registration" className="btn btn-primary">Apply Now</Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </main>
        </Layout >
    )
}
export default JobOpening