import Layout from "../layout"


function PrivacyPolicy() {
    return (
        <Layout pageTitle="Privacy Policy">
            <main>
                <div class="container mt-5">
                    <h2 class="mb-4">Privacy Policy</h2>

                    <p>Please read the following carefully. This Privacy Policy ("Policy") explains in general terms how Bhupathi Multitrade and controlled entities ("We" "our" or "us") protects the privacy of your personal information. This Policy sets out how Bhupathi Multitrade aims to protect the privacy of your personal information, your rights in relation to your personal information managed by Bhupathi Multitrade, and the way Bhupathi Multitrade collects, holds, uses, and discloses your personal information.</p>

                    <p>We are firmly committed to protecting the privacy and confidentiality of personal information and maintain robust physical, electronic, and procedural safeguards to protect personal information in our care.</p>


                    <h4 class="mt-4">1. What is personal information?</h4>
                    <p>Personal information is information or an opinion, in any form (whether true or not) about a natural person whose identity is apparent or can reasonably be ascertained from the information or opinion.</p>
                    <p>Sensitive information may be required to be collected in order for Bhupathi Multitrade to provide you with products and/or services. Sensitive information is a type of personal information and will only be collected where it is reasonably necessary for one or more of Bhupathi Multitrade functions and/or activities. An example of sensitive information and its use includes when we ask of you to disclose any medical issues or conditions that may be of relevance for your booking.</p>


                    <h4 class="mt-4">2. How do we collect personal information?</h4>
                    <p>Where practical we will collect personal information directly from you. Generally, this will be collected by us when you deal with us either in person, by telephone, letter, facsimile, email, or when you visit our website. We may request information about you when you purchase or make inquiries about accommodation or other products and services; when you enter competitions, register for promotions or when you request brochures or other information. We may also collect information when we invite you to complete surveys or provide us with feedback or when you register as a member and complete your personal profile and preferences.</p>
                    <p>Other organizations may also provide us with your information in order to allow us to facilitate the provision of a product and/or service of ours.</p>


                    <h4 class="mt-4">3. Why do we collect personal information?</h4>
                    <p>Bhupathi Multitrade collects, holds, uses, and discloses your personal information where it is reasonably necessary for the purposes of:</p>
                    <ol>
                        <li>Accounting, billing, and other internal administrative purposes;</li>
                        <li>Developing and facilitating a business relationship with you or an entity with which you are connected; and</li>
                        <li>Identifying and informing you of services that may be of interest to you.</li>
                    </ol>


                    <h4 class="mt-4">4. What personal information do we collect?</h4>
                    <p>Generally, the type of personal information we collect about you is the information that is needed to facilitate services and products to you. For example, we may collect details such as your name, mailing address, telephone number, email address, credit card number and expiry date, dietary requirements (if any) and health issues relevant to your travel arrangements. We also collect information that is required for use in the business activities of Bhupathi Multitrade including, for example, financial details necessary to process various transactions and any other information you may elect to provide to us. In some circumstances, Bhupathi Multitrade may also hold other personal information that has been provided by you.</p>
                    <p>We are required by law to obtain your consent to the collection of sensitive information. In order for us to provide the services you request, you consent to the collection of all information which is provided to us for use in accordance with this Policy unless you tell us otherwise.</p>


                    <h4 class="mt-4">5. When we act as agent</h4>
                    <p>When we sell products and services to you, we usually do so as an agent for the wholesaler or provider of those products and services. This means that we usually collect personal information about you both for our internal purposes, and on behalf of the parties for whom we act as an agent for their internal purposes. Accordingly, the consent you provide under this Policy to the collection of personal information by us applies equally to the parties whose products and services we sell.</p>
                    <p>We act as an agent for many hundreds of companies, so it is not possible for us to set out in this statement exactly how each of these companies will use your personal information, but we are happy to provide more specific information to you if you contact us via the details provided in section 12 of this Policy. Where personal information is used or disclosed, Bhupathi Multitrade takes steps reasonable in the circumstances to ensure it is relevant to the purpose for which it is to be used or disclosed. You are under no obligation to provide your personal information to Bhupathi Multitrade. However, without certain information from you, Bhupathi Multitrade may not be able to provide its products and/or services to you.</p>
                    <h4 class="mt-4">6. How do we use personal information?</h4>
                    <p>We may use and disclose your personal information for the purposes for which it was collected, or for a related or ancillary purpose such as any one or more of the following purposes:</p>
                    <ol>
                        <li>Identification of fraud or error;</li>
                        <li>Regulatory reporting and compliance;</li>
                        <li>Developing, improving, and marketing our products and services;</li>
                        <li>Servicing our relationship with you by, among other things, providing updates on promotions and services we think may interest you or you have identified an interest in;</li>
                        <li>Involving you in market research gauging customer satisfaction and seeking feedback regarding our relationship with you;</li>
                        <li>To facilitate your participation in loyalty programs;</li>
                        <li>For marketing activities; and</li>
                        <li>Internal accounting and administration.</li>
                    </ol>


                    <h4 class="mt-4">7. Direct Marketing</h4>
                    <p>Bhupathi Multitrade may use and disclose your personal information to inform you of products and/or services that may be of interest to you. Should you no longer wish to receive information on promotions and services we think may be of interest to you, participate in market research, or if you would like to opt-out of receiving any promotional or marketing communications, kindly e-mail us via our contact page.</p>


                    <h4 class="mt-4">8. Is the information disclosed to third parties?</h4>
                    <p>We may disclose your personal information:</p>
                    <ol>
                        <li>As permitted or required by law;</li>
                        <li>To various regulatory bodies and law enforcement officials and agencies to protect against fraud and for related security purposes;</li>
                        <li>To our third-party service providers as well as to our related entities;</li>
                        <li>To third parties such as hotels and other accommodation providers and other service providers for the purpose for which the information was collected or for a related purpose, for example, to facilitate and process your accommodation arrangements;</li>
                        <li>To third parties we have contracted with who may involve you in market research for the purpose of servicing our relationship with you and improving the services we provide.</li>
                    </ol>

                    <p>The third parties to whom we disclose your personal information may also be taken to have collected your personal information in their own right, for their internal use. For more information, see the heading above, titled “When we act as an agent”.</p>

                    <p>Where we engage third-party contractors to perform services for us, those third-party contractors may be required to handle your personal information. Under these circumstances, those third-party contractors must safeguard this information and must only use it for the purpose for which it was supplied.</p>

                    <p>Other than the above, we will not disclose your personal information without your consent unless disclosure is either necessary to prevent a threat to life or health, authorized or required by law, reasonably necessary to enforce the law, or necessary to investigate a suspected unlawful activity.</p>


                    <h4 class="mt-4">9. Trans-border data flows</h4>
                    <p>In providing our services to you, it may be necessary for us to forward personal information to relevant overseas third-party service providers.</p>

                    <p>Given the nature of the travel industry, it is inherently difficult for us to disclose as part of this Policy each of the specific countries in which the recipients are likely to be located.</p>

                    <p>Before disclosing any personal information to an overseas recipient, Pebble Holidays takes steps reasonable in the circumstances to ensure the overseas recipient complies with the Australian Privacy Principles or is bound by a substantially similar privacy scheme unless you consent to the overseas disclosure or it is otherwise required or permitted by law.</p>

                    <h4 class="mt-4">10. Security of information</h4>
                    <p>Bhupathi Multitrade has implemented stringent physical, electronic, and managerial security procedures in order to protect personal information from loss, misuse, alteration, or destruction. Bhupathi Multitrade regularly reviews security and encryption technologies and will strive to protect information to the fullest extent possible.</p>


                    <h4 class="mt-4">11. Access and correction of personal information</h4>
                    <p>Bhupathi Multitrade takes steps reasonable in the circumstances to ensure the personal information it holds is accurate, up-to-date, complete, relevant, and not misleading. Under the Privacy Act, you have a right to access and seek correction of your personal information that is collected and held by Pebble Holidays. If at any time you would like to access or correct the personal information that Bhupathi Multitrade holds about you, or you would like more information on Bhupathi Multitrade's approach to privacy, please contact Bhupathi Multitrade via the contact page on our website.</p>

                    <p>Bhupathi Multitrade will grant access to the extent required or authorized by the Privacy Act or other law and take steps reasonable in the circumstances to correct personal information where necessary and appropriate.</p>

                    <p>To obtain access to your personal information:</p>
                    <ol>
                        <li>You will have to provide proof of identity to ensure that personal information is provided only to the correct individuals and that the privacy of others is protected;</li>
                        <li>Bhupathi Multitrade requests that you be reasonably specific about the information you require; and</li>
                        <li>Bhupathi Multitrade may charge you a reasonable administration fee, which reflects the cost to Bhupathi Multitrade for providing access in accordance with your request.</li>
                    </ol>

                    <p>If Bhupathi Multitrade refuses your request to access or correct your personal information, Bhupathi Multitrade will provide you with written reasons for the refusal and details of complaint mechanisms. Bhupathi Multitrade will also take steps reasonable in the circumstance to provide you with access in a manner that meets your needs and the needs of Bhupathi Multitrade.</p>

                    <p>Bhupathi Multitrade will endeavor to respond to your request to access or correct your personal information within 30 days from your request.</p>


                    <h4 class="mt-4">12. Changes to our Policy</h4>
                    <p>From time to time, it may be necessary for us to review and revise this Policy. We reserve the right to change our Policy at any time; should this occur, the amendment would be posted on our website.</p>

                    <h4 class="mt-4">13. About our website</h4>


                    <h5 class="mt-3">13.1. When You Visit Our Website</h5>
                    <p>If you visit Bhupathi Multitrade website to browse, read, or download information, our system will log these movements. These website logs are not personally identifiable, and Bhupathi Multitrade makes no attempt to link them with the individuals who browse the site. When you come onto our website, we may collect certain information such as browser type, operating system, the website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyze how people use our site so that we can improve our services.</p>


                    <h5 class="mt-3">13.2. Use of Cookies</h5>
                    <p>A cookie is a small piece of text that is placed within the memory of a computer and can be later retrieved by web page servers. We use cookies to enhance your interaction and convenience with our website and do not use cookies to record any personal information.</p>


                    <h5 class="mt-3">13.3. Linked Sites</h5>
                    <p>Our website may contain links to other sites. We are not responsible for the privacy practices or the content of such websites that you directly interact with. We encourage you to read the privacy statements of any linked sites as their privacy policy may differ from ours.</p>


                </div>

            </main>
        </Layout>
    )
}

export default PrivacyPolicy